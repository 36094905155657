import * as React from 'react'
import Layout from "../components/layout/layout";
import {graphql} from "gatsby";
import {mapBox, space, locationspace} from "./location.module.scss";
import {useTranslation} from "react-i18next";
import MapLocation from "../components/maplocation/maplocation";
import {Trans} from "gatsby-plugin-react-i18next";

const LocationPage = () => {
    const {t} = useTranslation();
    return (
        <Layout pageTitle={t('pages.location.title')}>
            <div className={space}></div>
            <div className={"text-center"}>
                <div className={locationspace}></div>
                <div className={"infotxt h3"}>
                    <Trans>pages.location.whereis1</Trans>
                </div>
                <div className={"infotxt h4"}>
                    <Trans>pages.location.whereis2</Trans>
                </div>
                <div className={locationspace}></div>
            </div>
            <div className={mapBox}>
                <MapLocation/>
            </div>
        </Layout>
    )
}
export default LocationPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
